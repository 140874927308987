<template>
  <div class="view-index">
    <div class="container pb-5">
      <action-dispatcher action="courses/getWatching">
        <h2 v-if="watching.length" class="font-serif mt-5 mb-4">
          Continue de onde parou
        </h2>
        <div class="row g-4">
          <div
            class="col-xl-3 col-lg-4 col-md-6"
            v-for="lesson in watching"
            :key="lesson.id"
          >
            <continue-watching :lesson="lesson" />
          </div>
        </div>
      </action-dispatcher>

      <action-dispatcher action="courses/getCategories">
        <action-dispatcher action="courses/getAll">
          <div
            v-for="category in categories"
            :key="category.id"
            class="row g-4"
          >
            <h2 v-if="courses.length" class="col-12 font-serif pt-5">
              {{ category.name }}
            </h2>
            <div
              v-for="course in getCategoryCourses(category)"
              class="col-xl-3 col-lg-4 col-6"
              :key="course.id"
            >
              <router-link
                :to="{
                  name: 'course',
                  params: { id: course.id, slug: course.slug },
                }"
              >
                <div class="ratio ratio-3x4">
                  <img
                    :src="course.assets.cover_vertical || course.assets.cover"
                    class="img-fluid"
                  />
                </div>
              </router-link>
            </div>
          </div>
        </action-dispatcher>
      </action-dispatcher>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ContinueWatching from "@/components/index/ContinueWatching";

export default {
  components: { ContinueWatching },
  computed: {
    ...mapState("courses", ["courses", "watching", "categories"]),
  },
  methods: {
    getCategoryCourses(category) {
      return this.courses
        .sort((a, b) => a.sort - b.sort)
        .filter((course) => course.category.id === category.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-index {
  a {
    img {
      transition: filter 0.2s;
    }

    &:hover {
      img {
        filter: brightness(1.5);
      }
    }
  }
}
</style>
