<template>
  <router-link
    class="index-continue-watching text-light text-decoration-none"
    :to="{
      name: 'lesson',
      params: { id: lesson.id, slug: lesson.slug },
    }"
  >
    <div class="position-relative">
      <div class="ratio ratio-16x9 bg-dark">
        <img v-if="lesson.cover" :src="lesson.assets.small" class="img-fluid" />
      </div>
      <div class="progress">
        <div class="progress-bar" :style="{ width: progress + '%' }"></div>
      </div>
    </div>
    <h5 class="font-serif mt-2">{{ lesson.name }}</h5>
  </router-link>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({ lesson: Object });

const progress = computed(() => {
  const video = props.lesson.resourses.find(
    (resourse) => resourse.type === "videos"
  );
  return (100 * video.progress.stopped_at) / video.duration_seconds;
});
</script>

<style lang="scss" scoped>
.index-continue-watching {
  img {
    transition: filter 0.2s;
  }

  &:hover {
    img {
      filter: brightness(1.5);
    }
  }
  .progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    border-radius: 0;
    height: 4px;
    background-color: transparent;
  }
}
</style>
